var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"side-nav"}},[_c('div',{staticClass:"top-icons"},[(_vm.$can(_vm.access.services.READ_SERVICES, _vm.access.services.moduleName))?_c('router-link',{class:[
        'menu-btn',
        { 'current-view': _vm.isCurrentView(_vm.routes.SERVICE_CENTER) }
      ],attrs:{"to":{ name: 'serviceCenter', query: { tab: 'multi-punto' } }}},[_c('font-awesome-icon',{attrs:{"icon":"zubut"}})],1):_c('a',{staticClass:"menu-btn disabled"},[_c('font-awesome-icon',{attrs:{"icon":"zubut"}})],1),_c('div',{staticClass:"limit-menu top"}),_c('div',{class:[
        'menu-btn',
        {
          'current-view': _vm.isCurrentView(
            _vm.routes.ADMINS,
            _vm.routes.ADMINS_CREATE,
            _vm.routes.CLIENTS,
            _vm.routes.DRIVERS,
            _vm.routes.CLIENTS_CREATE
          )
        }
      ]},[_c('font-awesome-icon',{attrs:{"icon":"user"}}),_c('div',{staticClass:"dropdown-content"},[(_vm.$can(_vm.access.clients.READ_CLIENTS, _vm.access.clients.moduleName))?_c('router-link',{attrs:{"to":{ name: 'clients' }}},[_c('div',[_vm._v(" Clientes ")])]):_vm._e(),(_vm.$can(_vm.access.admins.READ_ADMINS, _vm.access.admins.moduleName))?_c('router-link',{attrs:{"to":{ name: 'admins' }}},[_c('div',[_vm._v(" Administradores ")])]):_vm._e(),(_vm.$can(_vm.access.drivers.READ_DRIVERS, _vm.access.drivers.moduleName))?_c('router-link',{attrs:{"to":{ name: 'drivers' }}},[_c('div',[_vm._v(" Mensajeros ")])]):_vm._e()],1)],1),(_vm.$can(_vm.access.services.READ_SERVICES, _vm.access.services.moduleName))?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Servicios'),expression:"'Servicios'",modifiers:{"right":true}}],class:[
        'menu-btn',
        { 'current-view': _vm.isCurrentView(_vm.routes.SERVICES) }
      ],attrs:{"to":{ name: 'services' }}},[_c('font-awesome-icon',{attrs:{"icon":"history"}})],1):_vm._e(),(_vm.hasReadTransactionAccess)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Transacciones'),expression:"'Transacciones'",modifiers:{"right":true}}],class:[
        'menu-btn',
        { 'current-view': _vm.isCurrentView(_vm.routes.TRANSACTIONS) }
      ],attrs:{"to":{ name: 'transactions' }}},[_c('font-awesome-icon',{attrs:{"icon":"payments"}})],1):_vm._e(),(
        _vm.$can(_vm.access.billing.CREATE_ADMIN_INVOICE, _vm.access.billing.moduleName)
      )?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Facturación'),expression:"'Facturación'",modifiers:{"right":true}}],class:[
        'menu-btn',
        { 'current-view': _vm.isCurrentView(_vm.routes.INVOICES) }
      ],attrs:{"to":{ name: 'invoices' }}},[_c('font-awesome-icon',{attrs:{"icon":"invoices"}})],1):_vm._e()],1),_c('div',{staticClass:"bottom-icons"},[_c('div',{staticClass:"limit-menu bottom mb-2"}),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Actividad'),expression:"'Actividad'",modifiers:{"right":true}}],class:['menu-btn', { 'current-view': _vm.isCurrentView(_vm.routes.LOGS) }],attrs:{"to":{ name: 'logs' }}},[_c('z-icon',{attrs:{"id":"logs","name":"Logs","view-box":"0 0 17 14","size":"18","color":"#AAAAAA"}})],1),(
        _vm.$can(
          _vm.access.notifications.READ_NOTIFICATIONS,
          _vm.access.notifications.moduleName
        )
      )?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Notificaciones'),expression:"'Notificaciones'",modifiers:{"right":true}}],class:['menu-btn', { 'current-view': _vm.showNotifications }],attrs:{"id":"log"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-notifications')}}},[_c('font-awesome-icon',{attrs:{"id":"log","icon":"notification"}})],1):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.appVersion),expression:"appVersion",modifiers:{"right":true}}],class:['menu-btn'],attrs:{"id":"info"}},[_c('font-awesome-icon',{attrs:{"id":"info","icon":"question-circle"}})],1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Cerrar Sesión'),expression:"'Cerrar Sesión'",modifiers:{"right":true}}],staticClass:"menu-btn",on:{"click":function($event){return _vm.$emit('logout')}}},[_c('font-awesome-icon',{attrs:{"icon":"logout"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }