<template>
  <div id="side-nav">
    <div class="top-icons">
      <router-link
        v-if="$can(access.services.READ_SERVICES, access.services.moduleName)"
        :to="{ name: 'serviceCenter', query: { tab: 'multi-punto' } }"
        :class="[
          'menu-btn',
          { 'current-view': isCurrentView(routes.SERVICE_CENTER) }
        ]"
      >
        <font-awesome-icon icon="zubut" />
      </router-link>
      <a v-else class="menu-btn disabled">
        <font-awesome-icon icon="zubut" />
      </a>
      <div class="limit-menu top"></div>
      <div
        :class="[
          'menu-btn',
          {
            'current-view': isCurrentView(
              routes.ADMINS,
              routes.ADMINS_CREATE,
              routes.CLIENTS,
              routes.DRIVERS,
              routes.CLIENTS_CREATE
            )
          }
        ]"
      >
        <font-awesome-icon icon="user" />
        <div class="dropdown-content">
          <router-link
            v-if="$can(access.clients.READ_CLIENTS, access.clients.moduleName)"
            :to="{ name: 'clients' }"
          >
            <div>
              Clientes
            </div>
          </router-link>
          <router-link
            v-if="$can(access.admins.READ_ADMINS, access.admins.moduleName)"
            :to="{ name: 'admins' }"
          >
            <div>
              Administradores
            </div>
          </router-link>
          <router-link
            v-if="$can(access.drivers.READ_DRIVERS, access.drivers.moduleName)"
            :to="{ name: 'drivers' }"
          >
            <div>
              Mensajeros
            </div>
          </router-link>
        </div>
      </div>
      <router-link
        v-if="$can(access.services.READ_SERVICES, access.services.moduleName)"
        v-tooltip.right="'Servicios'"
        :to="{ name: 'services' }"
        :class="[
          'menu-btn',
          { 'current-view': isCurrentView(routes.SERVICES) }
        ]"
      >
        <font-awesome-icon icon="history" />
      </router-link>
      <router-link
        v-if="hasReadTransactionAccess"
        v-tooltip.right="'Transacciones'"
        :to="{ name: 'transactions' }"
        :class="[
          'menu-btn',
          { 'current-view': isCurrentView(routes.TRANSACTIONS) }
        ]"
      >
        <font-awesome-icon icon="payments" />
      </router-link>
      <router-link
        v-if="
          $can(access.billing.CREATE_ADMIN_INVOICE, access.billing.moduleName)
        "
        v-tooltip.right="'Facturación'"
        :to="{ name: 'invoices' }"
        :class="[
          'menu-btn',
          { 'current-view': isCurrentView(routes.INVOICES) }
        ]"
      >
        <font-awesome-icon icon="invoices" />
      </router-link>
    </div>
    <div class="bottom-icons">
      <div class="limit-menu bottom mb-2"></div>
      <router-link
        v-tooltip.right="'Actividad'"
        :to="{ name: 'logs' }"
        :class="['menu-btn', { 'current-view': isCurrentView(routes.LOGS) }]"
      >
        <z-icon
          id="logs"
          name="Logs"
          view-box="0 0 17 14"
          size="18"
          color="#AAAAAA"
        />
      </router-link>
      <div
        v-if="
          $can(
            access.notifications.READ_NOTIFICATIONS,
            access.notifications.moduleName
          )
        "
        id="log"
        v-tooltip.right="'Notificaciones'"
        :class="['menu-btn', { 'current-view': showNotifications }]"
        @click.stop="$emit('toggle-notifications')"
      >
        <font-awesome-icon id="log" icon="notification" />
      </div>
      <div id="info" v-tooltip.right="appVersion" :class="['menu-btn']">
        <font-awesome-icon id="info" icon="question-circle" />
      </div>
      <div
        v-tooltip.right="'Cerrar Sesión'"
        class="menu-btn"
        @click="$emit('logout')"
      >
        <font-awesome-icon icon="logout" />
      </div>
    </div>
  </div>
</template>

<script>
import Routes from "@/constants/routes/paths";
import Access from "@/constants/access";

export default {
  name: "MenuDesktop",

  props: {
    showNotifications: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      access: Access,
      routes: Routes
    };
  },

  computed: {
    appVersion() {
      return `Versión ${this.$store.getters.appVersion}`;
    },
    hasReadTransactionAccess() {
      return (
        Access.transactions.HAS_ACCESS_READ_TRANSACTIONS() ||
        Access.transactions.HAS_ACCESS_READ_DRIVER_TRANSACTIONS() ||
        Access.transactions.HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS()
      );
    }
  },

  methods: {
    isCurrentView(...views) {
      const path = this.$route.fullPath.split(/\/|\?/g);
      return views.reduce(
        (accum, view) => accum || (path.length >= 2 && path[1] === view),
        false
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#side-nav {
  position: fixed;
  min-height: 100vh;
  width: 60px;
  background-color: $black;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12;
}

.limit-menu {
  border-width: 0.5px;
  border-color: $eclipse;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 10px;

  &.top {
    border-top-style: solid;
  }

  &.bottom {
    border-bottom-style: solid;
  }
}

.menu-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 0px 10px;
  height: 40px;
  width: 40px;
  color: $dark-gray;
  font-size: 1.2em;
}

.menu-btn:hover:not(.disabled),
.current-view {
  background: $zubut-blue;
  border-radius: 2px;
  cursor: pointer;
  color: $white;
}

.menu-btn:hover .dropdown-content {
  display: block !important;
  left: 0;
  top: 0;
}

.menu-btn.is-active {
  background: $zubut-blue;
  border-radius: 5px;
  cursor: pointer;

  svg {
    fill: $white !important;
  }
}

::v-deep .menu-btn:hover,
::v-deep .current-view {
  g {
    fill: white;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  font-size: 13px;
  background-color: $white-smoke;
  width: 135px;
  margin-left: 40px;
  box-shadow: 0px 8px 16px 0px rgba($black, 0.2);
  z-index: 1;

  a {
    color: $black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  a:hover {
    background: $gainsboro;
  }
}

.bottom-icons {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}
</style>
